<template>
  <OverviewWrapper>
    <AppCard :title="$t('All Domains')" title-actions>
      <template #titleActions>
        <v-btn to="Domains/create" color="secondary" fab small dark>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="domains"
        :page="pagination.page"
        :items-per-page="pagination.perPage"
        :loading="pending"
        hide-default-footer
      >
        <template #[`item.domain`]="{ item }"> {{ item.name }}.{{ item.tld }} </template>

        <template #[`item.client_id`]="{ item }">
          <router-link :to="{ name: 'clients-item', params: { id: item.client.id } }">
            {{ item.client.id }}
          </router-link>
        </template>

        <template #[`item.actions`]="{ item }">
          <OverviewActions type="domains" :item-id="item.id" info edit remove @onRemove="remove" />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@vue/composition-api'

import OverviewWrapper from '@/layouts/OverviewWrapper'
import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import OverviewActions from '@/components/UI/OverviewActions'

export default defineComponent({
  name: 'ViewDomains',
  components: {
    OverviewWrapper,
    AppCard,
    AppPagination,
    OverviewActions,
  },
  setup(props, { root: { $store, $route, $router }, root }) {
    const page = ref(1)
    const headers = [
      {
        sortable: false,
        text: 'Domain',
        value: 'domain',
      },
      {
        sortable: false,
        text: 'Status',
        value: 'status',
      },
      {
        sortable: false,
        text: 'Client',
        value: 'client_id',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '165px',
      },
    ]

    const activeQuery = computed(_ => root.$route.query)

    // store
    const pending = computed(_ => $store.state.domains.pending)
    const domains = computed(_ => $store.state.domains.domains)
    const pagination = computed(_ => $store.state.domains.pagination)
    const getDomains = page => $store.dispatch('domains/getDomains', page)
    const removeDomain = domainId => $store.dispatch('domains/removeDomain', domainId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getDomains(page.value)

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      getDomains(page.value)
    })

    const remove = id => {
      if (confirm('Sicher, dass die Domain gelöscht werden soll?')) {
        if (pending.value) {
          return
        }
        removeDomain(id)
          .then(_ => {
            addToast({
              msg: 'Domain erfolgreich gelöscht',
              type: 'success',
            })

            if (pagination.value.current === 1 && page.value !== 1) {
              $router.push({ query: { page: page.value - 1 } })
            } else {
              getDomains(page.value)
            }
          })
          .catch(error => {
            addToast({
              msg: 'Löschung fehlgeschlagen',
              type: 'error',
            })
          })
      }
    }

    return {
      pending,
      domains,
      headers,
      pagination,
      remove,
    }
  },
})
</script>
